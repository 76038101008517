import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "fs-7 text-dark fw-bold d-flex justify-content-between cursor-pointer align-items-center mb-1 flex-wrap"
}
const _hoisted_3 = { class: "badge bg-primary fs-9" }
const _hoisted_4 = { class: "fc-content text-wrap cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.alterCollapse(true))),
      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.alterCollapse(false))),
      class: "fc-event-title w-100 text-wrap cursor-pointer",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.cardClickEventHandler && _ctx.cardClickEventHandler(...args)))
    }, [
      _createElementVNode("div", null, _toDisplayString(_ctx.event.event.title), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.start) + " - " + _toDisplayString(_ctx.end), 1)
    ], 32),
    _createElementVNode("div", {
      onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.alterCollapse(false))),
      onMouseover: _cache[6] || (_cache[6] = ($event: any) => (_ctx.alterCollapse(true))),
      id: `collapse_${_ctx.event.event.extendedProps.shiftID}`,
      class: _normalizeClass(_ctx.collapseAllJobs ? 'collapse show' : 'collapse')
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.event.event.extendedProps.crew.breakdown, (crewSlot, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "fc-content bg-white py-1 px-1 text-wrap",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.cardClickEventHandler && _ctx.cardClickEventHandler(...args))),
          key: index
        }, [
          crewSlot
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createTextVNode(_toDisplayString(crewSlot.label.substring(8)), 1),
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.positions(crewSlot.label)), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            (
            crewSlot ||
              _ctx.event.event.extendedProps.shift_slots[index].applications
                .length == 0
          )
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "fs-7 text-dark fw-bold bg-warning d-flex flex-fill ps-1 pe-1",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.cardClickEventHandler && _ctx.cardClickEventHandler(...args)))
                }, _toDisplayString(crewSlot.label.substring(4)), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shiftSlots(
            _ctx.event.event.extendedProps.shift_slots[index]
          ), (slot) => {
              return (_openBlock(), _createElementBlock("div", {
                key: slot,
                class: _normalizeClass(["d-flex flex-fill ps-1 pe-1", slot.type == 'free' ? 'bg-warning' : 'bg-success'])
              }, _toDisplayString(slot.name), 3))
            }), 128))
          ])
        ]))
      }), 128))
    ], 42, _hoisted_1)
  ], 64))
}