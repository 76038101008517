
import {
  defineComponent,
  reactive,
  computed,
  onUnmounted,
  ref,
  watch,
  PropType,
  onBeforeMount
} from "vue";
import FullCalendar, {
  CalendarOptions,
  EventSourceInput
} from "@fullcalendar/vue3";
import CountriesDropdown from "@/components/dropdown/CountriesDropdown.vue";
import BranchesDropdown from "@/components/dropdown/BranchesDropdownMultiple.vue";
import CompaniesDropdown from "@/components/dropdown/ClientDropdownMultiple.vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import CustomEvent from "@/components/admin-calendar/CustomEventRender.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import { useStore } from "vuex";
import moment from "moment";
import { useAbility } from "@casl/vue";

type filters = {
  countryID: string | number;
  branchID: number | number[];
  companies: number[];
  searchedUserId: string | number;
};
interface UserSelectItem {
  value: string;
  userId: string;
}
export default defineComponent({
  name: "admin-calendar",
  components: {
    FullCalendar,
    CustomEvent,
    LoadingSpinner,
    CountriesDropdown,
    BranchesDropdown,
    CompaniesDropdown
  },
  props: {
    userId: {
      type: [Number, null] as PropType<number | null>,
      default: null
    }
  },
  setup(props) {
    const store = useStore();
    const { can } = useAbility();
    const isGeneratingCrewRoster = ref<boolean>(false);
    const data = computed(() => store.getters["AdminCalendarModule/getData"]);
    const dates = reactive({
      start: null,
      end: null
    });
    const showMyJobs = ref(false);
    const loading = computed(
      () => store.getters["AdminCalendarModule/loading"]
    );
    const inputModels = reactive<filters>({
      countryID: "",
      searchedUserId: "",
      branchID: [],
      companies: []
    });
    const collapseAllJobs = ref<boolean>(false);
    onBeforeMount(() => {
      if (props.userId) {
        store.dispatch("AdminCalendarModule/setSearchByUser", props.userId);
      }
    });
    watch(showMyJobs, () => {
      store.dispatch("AdminCalendarModule/showOnlyMyJobs", showMyJobs.value);
    });
    function handleDateChange(arg: any) {
      if (arg) {
        if (moment(dates.start).valueOf() == moment(arg.start).valueOf()) {
          dates.start = arg.start;
          dates.end = arg.end;
        } else {
          dates.start = arg.start;
          dates.end = arg.end;
          store.dispatch("AdminCalendarModule/setJobsDates", dates);
        }
      }
    }
    function downloadCrewRoster() {
      isGeneratingCrewRoster.value = true;
      const startDate = moment(dates.start).format("DD/MM/YYYY");
      const endDate = moment(dates.end).format("DD/MM/YYYY");
      const payload = {
        startDate: startDate,
        endDate: endDate,
        client: inputModels.companies[0]
      };
      store
        .dispatch("RosterModule/downloadRosterFile", payload)
        .then(() => {
          isGeneratingCrewRoster.value = false;
        })
        .catch(() => {
          isGeneratingCrewRoster.value = false;
        });
    }
    const showCrewRosterButton = computed(() => {
      return (
        can("can_download_crew_roster", "all") &&
        !loading.value &&
        inputModels.companies &&
        inputModels.companies.length == 1
      );
    });
    const calendarOptions = computed<CalendarOptions>(() => {
      return {
        plugins: [dayGridPlugin, interactionPlugin, dayGridPlugin],
        initialView: "dayGridWeek",
        weekNumbers: true,
        weekText: "Week ",
        customButtons: {
          downloadCrewList: {
            text: isGeneratingCrewRoster.value
              ? "Downloading"
              : "Download Crew Roster",
            click: function() {
              if (!isGeneratingCrewRoster.value) {
                downloadCrewRoster();
              }
            }
          }
        },
        headerToolbar: {
          start: "title",
          center: "dayGridMonth,dayGridWeek,dayGridDay",
          end: showCrewRosterButton.value
            ? "downloadCrewList today prev,next"
            : "today prev,next"
        },
        events: data.value,
        datesSet: handleDateChange,
        firstDay: 1
      };
    });

    function searchByCountry(countryID: number | string) {
      store.dispatch("AdminCalendarModule/setSearchByCountry", countryID);
    }
    function searchByBranch(branches: number | number[]) {
      store.dispatch("AdminCalendarModule/setSearchByBranch", branches);
    }
    function searchByClient(clients: number | number[]) {
      store.dispatch("AdminCalendarModule/setSearchByClient", clients);
    }
    function searchByUser(item: UserSelectItem) {
      store.dispatch("AdminCalendarModule/setSearchByUser", item.userId);
      store.dispatch("AdminCalendarModule/fetchData");
    }
    function handleClearUserSearch() {
      inputModels.searchedUserId = "";
      store.dispatch("AdminCalendarModule/setSearchByUser", null);
      store.dispatch("AdminCalendarModule/fetchData");
    }
    onUnmounted(() => {
      store.dispatch("AdminCalendarModule/resetState");
    });
    function forceCalenderRerender(): void {
      store.dispatch("AdminCalendarModule/fetchData");
    }
    function searchUsers(
      query: string,
      callBack: (arg: UserSelectItem[]) => void
    ) {
      store.dispatch("AdminCalendarModule/searchUsers", query).then(result => {
        callBack(
          result.map(item => {
            return {
              value: item.name,
              userId: item.id
            };
          })
        );
      });
    }
    return {
      forceCalenderRerender,
      showCrewRosterButton,
      calendarOptions,
      loading,
      data,
      showMyJobs,
      searchByUser,
      searchUsers,
      searchByCountry,
      searchByBranch,
      handleClearUserSearch,
      collapseAllJobs,
      searchByClient,
      handleDateChange,
      inputModels
    };
  }
});
