
import { computed, defineComponent, nextTick, onUpdated, ref, watch } from "vue";
import moment from "moment";
import router from "@/router/index";
import { Collapse } from "bootstrap";

type shiftSlot = {
  name: string;
  type: string;
};

export default defineComponent({
  name: "Event",
  props: {
    event: {
      type: Object,
      required: true
    },
    collapseAllJobs: {
      type: Boolean,
      default: () => false
    }
  },
  setup(props) {
    const start = computed((): string => {
      return moment(props.event.event.start).format("HH:mm");
    });

    const end = computed((): string => {
      return moment(props.event.event.end).format("HH:mm");
    });

    function shiftSlots(data) {
      const slots = [] as shiftSlot[];
      for (let i = 0; i < data.applications.length; i++) {
        slots.push({
          name: data.applications[i].assignee.short_name,
          type: "taken"
        });
      }
      if (data.positions - data.applications.length > 0) {
        slots.push({
          name: `${data.positions - data.applications.length} x ${
            data.skill.name
          }`,
          type: "free"
        });
      }
      return slots;
    }

    function cardClickEventHandler() {
      router.push(
        `/jobs/${props.event.event.extendedProps.jobID}/shifts?shift=${props.event.event.extendedProps.shiftID}`
      );
    }

    function positions(data) {
      const string = data.match(/(.*?\s){3}/g);
      return string[0];
    }

    function hideCollapse() {
      const collapseElement = new Collapse(
        document.getElementById(
          `collapse_${props.event.event.extendedProps.shiftID}`
        ),
        {
          toggle: false
        }
      );
      collapseElement.hide();
    }

    function alterCollapse(collapse: boolean): void {
      if (props.collapseAllJobs) return;
      const collapseElement = new Collapse(
        document.getElementById(
          `collapse_${props.event.event.extendedProps.shiftID}`
        ),
        {
          toggle: false
        }
      );
      collapseElement._element.removeEventListener(
        "shown.bs.collapse",
        hideCollapse
      );
      if (collapse) {
        collapseElement.show();
      } else {
        collapseElement._element.addEventListener(
          "shown.bs.collapse",
          hideCollapse
        );
        if (!collapseElement._element.classList.contains("collapsing")) {
          collapseElement.hide();
        }
      }
    }

    return {
      start,
      end,
      cardClickEventHandler,
      alterCollapse,
      shiftSlots,
      positions
    };
  }
});
